.bgm_audio {
    position: fixed;
    top: -100px;
    left: -100px;
    width: 10px;
    height: 10px;
    opacity: 0;
}
.icon-music {
    width: 1.1rem;
    height: 1.1rem;
    background: url(../images/icon-music.png) no-repeat center/contain;
    position: absolute;
    z-index: 100;
    left: 0.2rem;
    top: 1.6rem;
}
.icon-music.pause {
    opacity: 1;
    filter: grayscale(40%);
    transition: all 0.3s;
}
