body {
    margin: 0;
    font-family: 'Microsoft YaHei';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    position: relative;
    font-weight: bold;
    background: #000;
}
body,
html {
    height: 100%;
    overflow: hidden;
}
body {
    font-size: 0.12rem;
    line-height: 1;
}
* {
    line-height: 1;
    padding: 0;
    margin: 0;
}
button {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}
.home {
    width: 100vw;
    height: 100vh;
    .lottery_btn {
        font-weight: bold;
        white-space: nowrap;
        position: absolute;
        right: -0.17rem;
        width: 2.84rem;
        height: 1.28rem;
        background: url(../images/button_right_red.png) no-repeat right/contain;
        top: 1.5rem;
        z-index: 10;
        color: #fff;
        text-align: center;
        font-size: 0.26rem;
        line-height: 1rem;
        box-sizing: border-box;
        // padding-right: 0.3rem;
        z-index: 100;
    }
    .guide_btn {
        display: inline-block;
        font-weight: bold;
        position: absolute;
        top: 0.4rem;
        right: -0.03rem;
        width: 2.51rem;
        height: 0.94rem;
        z-index: 10;
        font-size: 0.45rem;
        background: url(../images/button_guid.png) no-repeat right/contain;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        z-index: 100;
        line-height: 0.9rem;
        text-decoration: none;
        vertical-align: top;
    }
    .background {
        position: absolute;
        top: 0;
        left: 0;
    }
    .slogan {
        width: 7.01rem;
        left: 50%;
        margin-left: -3.5rem;
        top: 0.1rem;
        position: absolute;
        z-index: 100;
    }
}

/**抽奖**/
.lottery_wrap {
    width: 16rem;
    height: 9rem;
    background: url('../images/lottery_bg.png') no-repeat center/contain;
    overflow: visible;
    position: absolute;
    .popup_close {
        right: 2.5rem;
        top: -0.2rem;
    }
    .lottery_chance {
        color: #e73e3b;
        position: absolute;
        line-height: 1;
        left: 0;
        width: 50%;
        left: 25%;
        text-align: center;
        top: 5%;
        font-size: 0.26rem;
        font-weight: bold;
    }
}
.gift_list {
    display: flex;
    width: 7.2rem;
    height: 7.2rem;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 1rem auto 0;
    position: relative;
    left: 0.15rem;
    justify-content: space-between;
}
.start_lottery {
    background: url(../images/lottery_start_btn.png) no-repeat center/contain;
    width: 30%;
    height: 30%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.48rem;
    color: #fff;
    font-weight: bold;
    &:disabled {
        filter: gray;
        filter: grayscale(80%);
    }
}
.lottery_footer {
    margin-top: -0.4rem;
    text-align: center;
    button {
        margin: 0.5rem 0.3rem 0;
    }
}
.gift_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 30%;
    text-align: center;
    font-size: 0.24rem;
    color: #df441a;
    background: #fff;
    border-radius: 0.2rem;
    line-height: 1;
    white-space: nowrap;
    position: relative;

    img {
        width: 70%;
        height: 70%;
        object-fit: contain;
    }
    p {
        width: 90%;
        margin: 0.1rem auto 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .isSelected {
        width: 119%;
        height: 119%;

        position: absolute;
        background: url(../images/gift_light.png) no-repeat center/100% 100%;
        left: -0.2rem;
        top: -0.2rem;
    }
}
.popup_mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 101%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 290;
}
.in-style {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
}
.out-style {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.popup_wrap {
    transition: all 0s;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 300;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*通用*/
.button_click_view {
    width: 3.23rem;
    height: 0.91rem;
    background: url(../images/click_view.png) no-repeat center/contain;
    text-align: center;
    font-size: 0.43rem;
    line-height: 0.8rem;
    overflow: hidden;
    text-indent: -999px;
    color: #fff;
}
.button_purple {
    width: 3.15rem;
    height: 1.11rem;
    background: url(../images/button_purple.png) no-repeat center/contain;
    font-size: 0.31rem;
    color: #ffffff;
    line-height: 1rem;
    text-align: center;
    font-weight: bold;
}
.button_orange {
    text-decoration: none;
    display: inline-block;
    width: 3.15rem;
    height: 1.11rem;
    background: url(../images/button_orange.png) no-repeat center/contain;
    font-size: 0.31rem;
    color: #ffffff;
    line-height: 1rem;
    text-align: center;
    font-weight: bold;
}
.popup_close {
    width: 1.26rem;
    height: 1.26rem;
    background: url(../images/close.png) no-repeat center/contain;
    position: absolute;
    right: 1rem;
    top: 0;
}
.button_confirm {
    width: 2.37rem;
    height: 1.08rem;
    background: url(../images/button_confirm.png) no-repeat center/contain;
}
.button_cancle {
    width: 2.37rem;
    height: 1.08rem;
    background: url(../images/button_cancle.png) no-repeat center/contain;
    filter: grayscale(20%);
    opacity: 0.8;
}
.button_copy {
    width: 3.99rem;
    height: 1.08rem;
    background: url(../images/button_copy.png) no-repeat center/contain;
}
.right_icon {
    width: 0.83rem;
    height: 0.83rem;
    background: url(../images/right_icon.png) no-repeat center/contain;
}

.gift_detail_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
}

// 显示获奖信息
.award_detail_wrap {
    position: relative;
    .button_orange {
        position: relative;
        top: -0.8rem;
    }
    .award_bg {
        width: 8rem;
        height: 7rem;
        background: url(../images/you_award_bg.png) no-repeat center/contain;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            height: 60%;
            width: 70%;
            object-fit: contain;
            margin-bottom: 0.4rem;
            margin-top: 0.5rem;
        }
    }
    .award_name {
        font-size: 0.4rem;
        color: #ffffff;
        font-weight: bold;
        position: relative;
        top: -0.3rem;
    }
    .award_name_big {
        top: 0.2rem;
        padding: 0.5rem 0;
        font-size: 0.6rem;
    }
    text-align: center;
}

/* noraml popup */
.popup_normal {
    position: relative;
    width: 9.59rem;
    // height: 5rem;
    background: url(../images/popup_bg_normal.png) no-repeat center/100% 100%;
    border-radius: 0.2rem;
    .popup_close {
        right: -0.5rem;
        top: -0.5rem;
    }
    .popup_title {
        height: 1rem;
        position: absolute;
        top: -0.3rem;
        color: #fff;
        font-size: 0.48rem;
        text-align: center;
        text-indent: -0.5em;
        line-height: 0.78rem;
    }
    .popup_title_small {
        width: 3.71rem;
        background: url(../images/popup_title_small.png) no-repeat
            center/contain;
    }
    .popup_title_big {
        width: 6.64rem;
        font-size: 0.34rem;
        background: url(../images/popup_title_big.png) no-repeat center/contain;
    }
    .popup_footer {
        width: 10.7rem;
        height: 0.8rem;
        background: url(../images/popup_bottom.png) no-repeat center/contain;
        position: absolute;
        bottom: -0.3rem;
        left: -0.5rem;
    }
    .popup_inner {
        padding: 1rem 0.9rem 0.8rem;
        color: #ffffff;
        font-size: 0.4rem;
    }
}
.address_form {
    & > div {
        text-align: center;
        margin-bottom: 0.25rem;
        label {
            text-align: left;
            width: 2.24rem;
            display: inline-block;
            font-weight: bold;
        }
        input {
            outline: none;
            width: 5rem;
            height: 0.76rem;
            line-height: 0.6rem;
            border-radius: 0.4rem;
            border: none;
            padding: 0.08rem 0.26rem;
            font-size: 0.36rem;
        }
    }
    .tip {
        &::before {
            content: ' ';
            @extend .right_icon;
            display: inline-block;
            vertical-align: middle;
        }
        span {
            line-height: 1.5;
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.5rem;
        }
    }
    .alian_center {
        position: absolute;
        bottom: -1.6rem;
        width: 100%;
        left: 0;
    }
}
.alian_center {
    text-align: center;
}
.cdk_wrap {
    p {
        font-size: 0.36rem;
        line-height: 1.5;
    }
    .button_copy {
        margin-top: 0.4rem;
    }
}
.popup_color {
    position: relative;
    width: 9.37rem;
    height: 4.9rem;
    background: no-repeat center/contain;
    .button_wrap {
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 1rem;
    }
    .popup_close {
        right: 0.3rem;
        top: -0.5rem;
    }
}
.button_enable {
    width: 1.59rem;
    height: 0.73rem;
    background: url(../images/btn_enable.png) no-repeat center/contain;
    color: #fff;
    font-size: 0.3rem;
    &:disabled {
        background: url(../images/btn_done.png) no-repeat center/contain;
    }
    &.disabled {
        background: url(../images/btn_disable.png) no-repeat center/contain;
    }
}

.my_award_list,
.act_list {
    position: relative;
    max-height: 5rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    li {
        margin: 0.25rem 0;
    }
    .award_name {
        display: inline-block;
        font-size: 0.34rem;
        margin-left: 1rem;
        max-width: 80%;
        white-space: nowrap;
    }
}
.act_list {
    .award_name {
        margin-left: 0.1rem;
        display: inline-block;
        vertical-align: middle;
        font-size: 0.36rem;
        width: 78%;
        line-height: 1.5;
    }
}
.loading {
    text-align: center;
    padding: 1rem 0;
}
.popup_pingpai {
    height: 7.69rem;
    .button_wrap {
        bottom: 0.7rem;
    }
}
.popup_leyuan {
    width: 11.83rem;
    height: 8.91rem;
    .button_wrap {
        bottom: 0.7rem;
    }
    .game_logo_wrap {
        width: 7rem;
        margin: 2.4rem auto 0;
        & > div {
            white-space: nowrap;
        }
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        .logo_wrap {
            display: inline-block;
            width: 2.3rem;
            height: 1.3rem;
            overflow: hidden;
            img {
                width: 120%;
                position: relative;
                left: -10%;
                top: -20%;
                height: 140%;
                object-fit: contain;
            }
        }
    }
}
.popup_confirm {
    .confirm_text {
        text-align: center;
        padding: 0.7rem 0;
        font-size: 0.5rem;
        font-weight: bold;
    }
    .button_wrap {
        & > button {
            margin: 0 0.3rem;
        }
    }
}
.popup_confirm_outer {
    z-index: 400;
}

.popup_guid {
    position: relative;
    width: 14.35rem;
    height: 8.87rem;
    background: url(../images/popup_bg_normal.png) no-repeat center/100% 100%;
    .popup_close {
        right: -0.5rem;
        top: -0.5rem;
        z-index: 100;
    }
    .swiper-container {
        margin: 1rem auto 0;
        width: 12.6rem;
        height: 6.9rem;
        padding-bottom: 1.8rem;
    }
    .swiper-slide {
        & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .clone_swiper {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        // background: #000;
        // opacity: 0.5;
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            background: none;
            width: 4px;
            height: 4px;
            border: 2px solid #fff;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background: #fff;
        }
    }
}
.design_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../images/design.png) no-repeat top/cover;
}
.popup_loading {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    font-size: 0.34rem;
    font-weight: bold;
}

.brand_list {
    text-align: center;
    margin: 0 auto;
    margin-top: 2.8rem;
    width: 75%;
    button {
        margin: 0 0.1rem;
        width: 1.4rem;
        height: 1.5rem;
        margin-bottom: 0.2rem;
        opacity: 0;
    }
}
.tip_text {
    text-align: center;
    color: #fff;
    margin-top: 0.2rem;
    font-size: 0.3rem;
}
.text_shadow {
    text-shadow: 0px 0px 4px #666;
}
.left_right {
    animation: left_right 3s ease-in-out 0s infinite alternate-reverse both;
}
.up_down {
    animation: up_down 3s ease-in-out 0s infinite alternate-reverse both;
}
.duration4 {
    animation-duration: 4s;
}
.duration10 {
    animation-duration: 10s;
}
.delay1 {
    animation-delay: 1s;
}
.delay2 {
    animation-delay: 2s;
}
.delay3 {
    animation-delay: 3s;
}
@keyframes left_right {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(6%, 0);
    }
}
@keyframes up_down {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(0, 4%);
    }
}
@keyframes scale {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}
.oneline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.animate {
    transition: all 0.3s;
}
.animate_in {
    opacity: 0;
    transform: scale(0);
}
.animate_out {
    opacity: 1;
    transform: scale(1);
}

.award_result_page {
    // background: rgba(0, 0, 0, 0.5);
}
.thanks_pop {
    width: 8rem;
    height: 4rem;
    background: url(../images/thanks_bg.png) no-repeat center/contain;
}
