.icon-share {
    position: absolute;
    left: 0.2rem;
    top: 0.2rem;
    width: 1.12rem;
    height: 1.13rem;
    background: url(../images/icon-share.png) no-repeat center/contain;
    z-index: 100;
}
.share-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.85);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    img {
        width: 90%;
        height: 90%;
        object-fit: contain;
        pointer-events: auto;
        user-select: auto;
        -webkit-user-select: auto; /*禁用手机浏览器的用户选择功能 */
    }
}
