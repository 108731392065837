.background {
    background: #000;
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../images/bg.jpg) no-repeat center/cover;
    & > .inner {
        width: 100%;
        // height: 10.8rem;
        position: absolute;
        // left: 50%;
        // margin-left: ;
        & > .design {
            width: 100%;
        }
        & > img {
            max-width: 100%;
            position: absolute;
        }
        & > .slogan {
            width: 7.01rem;
            left: 50%;
            margin-left: -3.5rem;
            top: 1rem;
        }
    }

    .hotarea {
        // background: red;
        width: 10%;
        height: 10%;
        position: absolute;
        bottom: 10%;
        left: 10%;
        opacity: 0.5;
    }
    .hotarea_shikong {
        width: 18%;
        height: 68%;
        position: absolute;
        bottom: 0%;
        left: 1%;
    }
    .hotarea_guangchang {
        width: 20%;
        height: 48%;
        position: absolute;
        bottom: 24%;
        left: 20%;
    }
    .hotarea_pingpai {
        width: 20%;
        height: 48%;
        position: absolute;
        bottom: 2%;
        left: auto;
        right: 0;
    }
    .hotarea_leyuan {
        width: 24%;
        height: 45%;
        position: absolute;
        bottom: 24%;
        left: auto;
        right: 20%;
    }
    .hotarea_guo {
        width: 36%;
        height: 23%;
        position: absolute;
        bottom: 0;
        left: 29%;
    }
    .hotarea_guo2 {
        width: 13%;
        height: 27%;
        position: absolute;
        bottom: 23%;
        left: 41%;
    }
    .cloud_left {
        width: 35.1%;
        z-index: 21;
        bottom: 10%;
        left: -1%;
    }
    .cloud_right {
        width: 26.5%;
        bottom: 10%;
        right: 15%;
        z-index: 21;
    }
}
.guid_tip_text {
    width: 8.82rem;
    height: 1.59rem;
    background: url(../images/tip_text_bg.png) no-repeat center/contain;
    text-align: center;
    line-height: 1.59rem;
    font-size: 0.44rem;
    color: #fff;
    font-weight: bold;
    position: fixed;
    left: 50%;
    top: 10%;
    margin-left: -4.41rem;
    z-index: 100;
}
.icon-skip {
    width: 1.12rem;
    height: 1.12rem;
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    background: url(../images/icon-skip.png) no-repeat center/contain;
    z-index: 100;
}
.click-tip {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: fixed;
    right: 5rem;
    bottom: 5rem;
    z-index: 101;
    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 50%;
        animation: suofang 0.6s ease-out 0s infinite alternate-reverse both;
    }
    &::before {
        content: ' ';
        position: absolute;
        // background: #fff;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: suofang 1s ease-in-out 0.5s infinite normal both;
    }
}

@keyframes suofang {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.5;
    }
}
