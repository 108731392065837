.pc_wrap {
    width: 100vw;
    height: 100vh;
    background: url(../images/design.png) no-repeat top/cover;
}
.popup_qrcode {
    width: 7.82rem;
    height: 5.87rem;
    position: relative;
    background: url(../images/popup_qrcode_bg.png) no-repeat center/contain;
    display: flex;
    justify-content: center;
    align-items: center;
    .qrcode_wrap {
        width: 2.91rem;
        height: 2.94rem;
        background: url(../images/qrcode_bg.png) no-repeat center/100% 100%;
        padding: 0.2rem;
        box-sizing: border-box;
        margin-left: -0.4rem;
        canvas {
            width: 100% !important;
            height: 100% !important;
            border-radius: 10px;
        }
    }
}
